<template>
  <div>
    <div v-if="!mobile" :style="`height: ${footerHeight}; width: 100%;`"></div>
    <v-footer
      :height="footerHeight"
      :absolute="!mobile"
      style="z-index: 10"
      padless
    >
      <v-container fluid :class="{ 'pa-0': !mobile }">
        <v-row :no-gutters="!mobile">
          <v-col cols="12" md="auto">
            <v-img
              :height="footerHeight - 8"
              width="150"
              :class="{ 'my-3': mobile, 'mx-auto': mobile }"
              contain
              src="@/assets/creditcards.svg"
            ></v-img>
          </v-col>
          <v-col
            cols="12"
            md="auto"
            :class="{
              'text-right': !mobile,
              'flex-grow-1': !mobile,
              'mb-3': mobile,
            }"
          >
            <v-btn
              :block="mobile"
              v-for="(link, index) in links"
              :key="index"
              :to="link.route"
              :x-small="$vuetify.breakpoint.xsOnly"
              text
              tile
            >
              {{ link.text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "AppFooterComponent",
  data() {
    return {
      links: [
        {
          text: "FAQ",
          route: { name: "faq" },
        },
        {
          text: "Datenschutz",
          route: { name: "privacy" },
        },
        {
          text: "Impressum",
          route: { name: "imprint" },
        },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    footerHeight() {
      return this.mobile ? "auto" : 36;
    },
  },
};
</script>
