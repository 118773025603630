import { http } from "@/ajax";

export const meta = {
  namespaced: true,
  state: {
    logo_label: null,
  },
  mutations: {
    bootstrap(state) {
      http.get("/api/v1/metadata").then(({ data }) => {
        state.logo_label = data.logo_label;
      });
    },
  },
  getters: {
    logoLabel({ logo_label }) {
      return logo_label;
    },
  },
};
