import { Model } from "@/models/Model";
import store from "@/store";
import moment from "moment";

export class Invoice extends Model {
  $resource() {
    return "invoice";
  }

  $attributes() {
    return [
      "issued_at",
      "due_at",
      "status",
      "type",
      "items",
      "creditor_additional_information",
      "creditor_address",
      "debtor_address",
      "paid_at",
      "payment_details",
      "remarks",
    ];
  }

  $setup() {
    this.$dates = [
      "issued_at|date|format:YYYY-MM-DD",
      "due_at|date|format:YYYY-MM-DD",
    ];
  }

  $revert() {
    return store
      .dispatch(`resources/read`, {
        resource: "invoice",
        query: {
          include: "items",
          "filter[id]": this.id,
        },
      })
      .then((id) => {
        let reverted = new Invoice(store.getters["resources/read"](id)[0]);

        let credit = reverted.$clone();
        credit.id = null;
        credit.type = 1;
        credit.status = 1;
        credit.$save();

        let draft = reverted.$clone();
        draft.id = null;
        draft.status = 2;

        reverted.status = 1;
        reverted.$save();

        return draft;
      });
  }

  $copy() {
    return store
      .dispatch(`resources/read`, {
        resource: "invoice",
        query: {
          include: "items",
          "filter[id]": this.id,
        },
      })
      .then((id) => {
        let original = new Invoice(store.getters["resources/read"](id)[0]);

        let copy = original.$clone();
        copy.id = null;
        copy.status = 2;

        return copy;
      });
  }

  get localized_issued_at() {
    return moment(this.issued_at).format(this.$t("moment.YYYY-MM-DD"));
  }

  get localized_due_at() {
    return moment(this.due_at).format(this.$t("moment.YYYY-MM-DD"));
  }

  get humanreadable_due_at() {
    return moment(this.due_at).fromNow();
  }

  get download_name() {
    return (this.bill_number ?? "draft") + ".pdf";
  }

  get overdue_status() {
    /**
     * 0 = not overdue
     * 1 = overdue (under 7 days)
     * 2 = long overdue (7 or more days)
     */
    if (!this.isNotPaid()) {
      return 0;
    }
    let days_left = moment(this.due_at).diff(moment(), "days");
    return days_left >= 0 ? 0 : days_left <= -7 ? 2 : 1;
  }

  get icon() {
    return this.type === 0
      ? this.status === 0
        ? "fa-envelope-open-dollar"
        : this.status === 1
        ? "fa-usd-circle"
        : this.status === 2
        ? "fa-file-invoice-dollar"
        : this.status === 3
        ? "fa-shredder"
        : "fa-ban"
      : "fa-money-check-alt";
  }

  paid() {
    this.status = 1;

    return this.$save();
  }

  lost() {
    if (confirm("Rechnung wirklich als verloren eintragen?")) {
      this.status = 3;

      return this.$save();
    }
  }

  isNotPaid() {
    return this.status === 0;
  }

  isPaid() {
    return this.status === 1;
  }

  isDraft() {
    return this.status === 2;
  }

  isLost() {
    return this.status === 3;
  }

  isCorrectable() {
    return this.type === 0 && (this.status === 0 || this.status === 1);
  }
}
