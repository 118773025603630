<template>
  <v-app>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="8" class="text-center ma-9">
          <h1 class="display-4 mb-3">Wartung</h1>
          <v-divider></v-divider>
          <p class="text-body-1">
            Purma wird gerade gewartet. Die Seite lädt automatisch sobald die
            Wartung beendet ist.
          </p>
          <v-progress-circular
            color="primary"
            indeterminate
            size="250"
            width="30"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import store from "./store";

export default {
  name: "MaintanceComponent",
  data: () => ({
    checkInterval: 30000,
  }),
  created() {
    setInterval(() => {
      store.dispatch("auth/bootstrap").then(() => window.location.reload());
    }, this.checkInterval);
  },
};
</script>
