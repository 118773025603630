import timeTracking from "@/routes/timeTracking";
import email from "@/routes/email";

export default {
  path: "",
  component: () =>
    import(/* webpackChunkName: "member" */ "@/layouts/Member.vue"),
  children: [
    timeTracking,
    email,
    {
      path: "/overview",
      name: "home",
      redirect: { name: "work-session" },
    },
    {
      path: "/logout",
      name: "logout",
      component: () =>
        import(/* webpackChunkName: "logout" */ "@/views/Logout.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/user/setting",
      name: "user.setting",
      component: () =>
        import(
          /* webpackChunkName: "user.setting" */ "@/views/user/Setting.vue"
        ),
    },
    {
      path: "/invoice/list",
      name: "invoice.list",
      component: () =>
        import(
          /* webpackChunkName: "invoice.list" */ "@/views/invoice/List.vue"
        ),
      meta: { middleware: ["can:manage_invoices"] },
    },
    {
      path: "/invoice/create/:debtorId?",
      name: "invoice.create",
      component: () =>
        import(
          /* webpackChunkName: "invoice.create" */ "@/views/invoice/Create.vue"
        ),
      meta: { middleware: ["can:manage_invoices"] },
    },
    {
      path: "/invoice/edit/:id",
      name: "invoice.edit",
      component: () =>
        import(
          /* webpackChunkName: "invoice.edit" */ "@/views/invoice/Edit.vue"
        ),
      meta: { middleware: ["can:manage_invoices"] },
    },
  ],
};
