import router from "@/router";
import { setAuthorizationToken, http } from "@/ajax";
import { joinPrivateChannels, leavePrivateChannels } from "@/socket";

export const auth = {
  namespaced: true,
  state: {
    token: {},
    encodedToken: null,
    user: null,
    permissions: [],
    impersonator_token: null,
  },
  actions: {
    /**
     * bootstrap application, recovers old session if there is any
     *
     * @return {Promise}
     */
    bootstrap({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        // check for token in local storage
        const token = localStorage.getItem("access_token");

        if (token === null) {
          // if there is no token we are a guest
          resolve();
          return;
        } else {
          // if there is a token load it into the app
          commit("token", token);

          // token is too old
          if (new Date(state.token.payload.exp * 1000) < new Date()) {
            commit("logout");
            router.go({ name: "login" });
          }

          dispatch("customerSorted/load", null, { root: true });

          http
            .get("/api/v1/validate")
            .then(({ data }) => {
              if (data.user === undefined) {
                // token is invalid
                commit("logout");
                router.go({ name: "login" });
              } else {
                // token is valide update user data
                commit(
                  "impersonatorToken",
                  localStorage.getItem("impersonator_token")
                );
                commit("user", data.user);
                commit(
                  "permissions",
                  data.user.current_permissions.map(
                    (permission) => permission.name
                  )
                );
                joinPrivateChannels();
              }
              resolve();
            })
            .catch(() => {
              reject("Error during auth/bootstrap");
            });
        }
      });
    },
    register({ commit, rootState }, data) {
      return http.post("/api/v1/register", data).then(() => {
        router.push({ name: "registered" });
      });
    },
    login({ commit, rootState, dispatch }, credentials) {
      localStorage.removeItem("impersonator_token");

      return http.post("/api/v1/login", credentials).then(({ data }) => {
        commit("token", data.token.access_token);
        commit("user", data.user);
        commit(
          "permissions",
          data.user.current_permissions.map((permission) => permission.name)
        );
        joinPrivateChannels();
        dispatch("customerSorted/load", null, { root: true });
      });
    },
    logout({ commit, dispatch }) {
      dispatch("customerSorted/clear", null, { root: true });
      return http.post("/api/v1/logout").finally(() => {
        commit("logout");
      });
    },
    refresh({ commit, dispatch }) {
      return http.get("/api/v1/validate").then(({ data }) => {
        commit("user", data.user);
        dispatch("customerSorted/load", null, { root: true });
      });
    },
    impersonate({ state }, id) {
      return http.post(`/api/v1/impersonate/${id}`).then(({ data }) => {
        localStorage.setItem("impersonator_token", state.encodedToken);
        localStorage.setItem("access_token", data.token.access_token);
        location.reload(); // reload site so we enter as the impersonated user
      });
    },
    stopImpersonation({ state }) {
      if (state.impersonator_token === null) {
        return;
      }

      localStorage.removeItem("impersonator_token");
      localStorage.setItem("access_token", state.impersonator_token);
      location.reload(); // reload site so we enter as the original user
    },
  },
  mutations: {
    impersonatorToken(state, token) {
      state.impersonator_token = token;
    },
    token(state, encodedToken) {
      localStorage.setItem("access_token", encodedToken);

      const splitToken = encodedToken.split(".");

      try {
        state.token.header = JSON.parse(atob(splitToken[0]));
        state.token.payload = JSON.parse(atob(splitToken[1]));
        state.token.signature = splitToken[2];
      } catch {
        localStorage.removeItem("access_token");
        localStorage.removeItem("impersonating_token");
        location.reload(); // reload site so we enter as the original user
      }

      state.encodedToken = encodedToken;

      setAuthorizationToken(`Bearer ${encodedToken}`);
    },
    logout(state) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("impersonating_token");

      if (state.user) {
        leavePrivateChannels(state.user.id);
      }

      state.token = {};
      state.encodedToken = null;
      state.user = null;
      setAuthorizationToken(undefined);
    },
    user(state, user) {
      state.user = user;
    },
    permissions(state, permissions) {
      state.permissions = permissions;
    },
  },
  getters: {
    check({ encodedToken }) {
      return encodedToken !== null;
    },
    guest({ encodedToken }) {
      return encodedToken === null;
    },

    /**
     * check if a user has any of the permissions
     *
     * @param  {String} name
     */
    can:
      ({ permissions }) =>
      (name) => {
        return permissions.some((permission) => permission === name);
      },
    id({ user }) {
      return !user ? null : user.id;
    },
    impersonating({ impersonator_token }) {
      return impersonator_token !== null;
    },
    user({ user }) {
      return user;
    },
    shortName({ user }) {
      return user.name
        .split(" ")
        .map((str) => str.charAt(0))
        .join();
    },
    team({ user }) {
      return user ? user.current_team : {};
    },
    primaryCustomer(state, getters) {
      return getters.team.primary_customer;
    },
    isTeamLeader({ user }) {
      return (
        user &&
        user.current_team &&
        user.current_team.pivot &&
        user.current_team.pivot.role === 0
      );
    },
    tokenIdMatches:
      ({ encodedToken }) =>
      (tokenId) => {
        return encodedToken !== null ? encodedToken.id === tokenId : false;
      },
  },
};
