import { Model } from "@/models/Model";
import moment from "moment";
import store from "@/store";

export class WorkSession extends Model {
  $resource() {
    return "workSession";
  }

  $attributes() {
    return [
      "description",
      "ended_at",
      "locked",
      "started_at",
      "type",
      "user_id",
      "customer_id",
    ];
  }

  get localized_started_at() {
    return moment(this.started_at).format(this.$t("moment.YYYY-MM-DD HH:mm"));
  }

  get localized_ended_at() {
    return moment(this.ended_at).format(this.$t("moment.YYYY-MM-DD HH:mm"));
  }

  $setup() {
    this.$dates = ["started_at", "ended_at"];
  }

  $duration() {
    const ended_at = this.ended_at
      ? moment(this.ended_at)
      : moment(store.getters["time/timestamp"]);

    return moment.duration(ended_at.diff(moment(this.started_at)));
  }

  $durationInMinutes() {
    return parseInt(this.$duration().asMinutes());
  }

  $durationInSeconds() {
    return parseInt(this.$duration().asSeconds());
  }

  $durationHumanReadable() {
    const duration = this.$duration();
    const hours = parseInt(duration.asHours());
    const minutes = (parseInt(duration.asMinutes()) % 60).toLocaleString(
      "de-DE",
      {
        minimumIntegerDigits: 2,
      }
    );
    const seconds = (parseInt(duration.asSeconds()) % 60).toLocaleString(
      "de-DE",
      {
        minimumIntegerDigits: 2,
      }
    );
    return `${hours}:${minutes}:${seconds}`;
  }

  $resumable() {
    return [0, 1].includes(this.type);
  }

  $resume() {
    if (this.$resumable()) {
      const session = this.$clone();
      session.ended_at = null;
      session.id = null;
      session.started_at = "now";
      session.$save();
    } else {
      throw "This WorkSession can not be resumed";
    }
  }

  $save() {
    if ((this.id === null || this.id === undefined) && this.type === 2) {
      return store.dispatch("resources/create", {
        resource: "absent",
        data: {
          ...this.$values(),
        },
      });
    }
    return super.$save();
  }
}
