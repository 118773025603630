export default {
  path: "",
  component: () =>
    import(/* webpackChunkName: "public" */ "@/layouts/Public.vue"),
  children: [
    {
      path: "/",
      name: "public-home",
      component: () =>
        import(/* webpackChunkName: "public-home" */ "@/views/Login.vue"),
      meta: {
        mobile: true,
        middleware: ["guest"],
      },
    },
    {
      path: "/login/:verifyEmailToken?",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
      meta: {
        mobile: true,
        middleware: ["guest"],
      },
    },
    {
      path: "register",
      name: "register",
      component: () =>
        import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
      meta: {
        mobile: true,
        middleware: ["guest"],
      },
    },
    {
      path: "/registered",
      name: "registered",
      component: () =>
        import(/* webpackChunkName: "registered" */ "@/views/Registered.vue"),
      meta: {
        mobile: true,
        middleware: ["guest"],
      },
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "contact" */ "@/views/Contact.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/contacted",
      name: "contacted",
      component: () =>
        import(/* webpackChunkName: "contacted" */ "@/views/Contacted.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () =>
        import(/* webpackChunkName: "privacy" */ "@/views/Privacy.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () =>
        import(/* webpackChunkName: "imprint" */ "@/views/Imprint.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/team/invite/accept/:token/:name?",
      name: "team.invite.accept",
      component: () =>
        import(
          /* webpackChunkName: "team.invite.accept" */ "@/views/team/Accept.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/password-reset",
      name: "password.reset",
      component: () =>
        import(
          /* webpackChunkName: "password.reset" */ "@/views/password/Reset.vue"
        ),
      meta: {
        mobile: true,
        middleware: ["guest"],
      },
    },
    {
      path: "/password-recovery/:token",
      name: "password.recovery",
      component: () =>
        import(
          /* webpackChunkName: "password.recovery" */ "@/views/password/Recovery.vue"
        ),
      meta: {
        mobile: true,
        middleware: ["guest"],
      },
    },
    {
      path: "/getting-started",
      name: "getting-started",
      component: () =>
        import(
          /* webpackChunkName: "getting-started" */ "@/views/public/GettingStarted.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () =>
        import(/* webpackChunkName: "faq" */ "@/views/public/FAQ.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/demo",
      name: "demo",
      component: () =>
        import(/* webpackChunkName: "demo" */ "@/views/public/Demo.vue"),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/features",
      name: "features",
      component: () =>
        import(
          /* webpackChunkName: "features" */ "@/views/public/Features.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/verify-email/:email",
      name: "verify-email",
      component: () =>
        import(
          /* webpackChunkName: "verify-email" */ "@/views/VerifyEmail.vue"
        ),
      meta: {
        mobile: true,
      },
    },
  ],
};
