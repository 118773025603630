<style lang="scss">
html {
  overflow-y: auto; /* prevent inactive scrollbar */
}

.notifications {
  .notification-title {
    font-size: 16px;
  }

  .notification-content {
    font-size: 14px;
  }
}
</style>

<template>
  <v-app>
    <app-update></app-update>
    <notifications
      class="notifications"
      classes="vue-notification elevation-3"
      position="bottom right"
      width="350px"
    />
    <router-view />
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppUpdate from "@/components/AppUpdate.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppFooter,
    AppUpdate,
  },
};
</script>
