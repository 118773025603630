import store from "@/store";

let roles = {
  leader: 0,
};

function getFallbackPage() {
  return store.getters["auth/check"] ? "home" : "public-home";
}

/**
 * a middleware needs to return true or use the next()
 * function to navigate somewhere else
 */
export default {
  can: function (param, next) {
    return store.getters["auth/can"](param)
      ? true
      : next({ name: getFallbackPage() });
  },
  role: function (param, next) {
    return store.getters["auth/team"].pivot?.role === roles[param]
      ? true
      : next({ name: getFallbackPage() });
  },
  guest(param, next) {
    return store.getters["auth/guest"]
      ? true
      : next({ name: getFallbackPage() });
  },
  admin(param, next) {
    return store.getters["auth/user"]?.is_admin
      ? true
      : next({ name: getFallbackPage() });
  },
  verified(param, next) {
    return store.getters["auth/user"]?.email_verified_at
      ? true
      : next({
          name: "verify-email",
          params: { email: store.getters["auth/user"].email },
        });
  },
};
