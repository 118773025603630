export default {
  path: "admin",
  component: () =>
    import(/* webpackChunkName: "Admin" */ "@/layouts/Admin.vue"),
  meta: { middleware: ["admin"] },
  children: [
    {
      path: "overview",
      name: "admin-home",
      component: () =>
        import(
          /* webpackChunkName: "admin.overview" */ "@/views/admin/Overview.vue"
        ),
    },
  ],
};
