import { Model } from "@/models/Model";
import moment from "moment";

export class SickLeave extends Model {
  $resource() {
    return "sickLeave";
  }

  $attributes() {
    return ["started_at", "ended_at", "user_id", "team_id"];
  }

  $setup() {
    this.$dates = [
      "started_at|date|format:YYYY-MM-DD",
      "ended_at|date|format:YYYY-MM-DD",
    ];
  }

  get localized_started_at() {
    return moment(this.started_at).format(this.$t("moment.YYYY-MM-DD"));
  }

  get localized_ended_at() {
    return moment(this.ended_at).format(this.$t("moment.YYYY-MM-DD"));
  }

  get duration_in_days() {
    const duration = moment.duration(
      moment(this.ended_at).diff(moment(this.started_at))
    );
    return parseInt(duration.asDays()) + 1;
  }
}
