import Vue from "vue";
import { http } from "@/ajax";

Vue.use({
  install() {
    Vue.prototype.$download = function (url, query, filename = "download") {
      return http
        .get(url, {
          params: query,
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        });
    };
  },
});
