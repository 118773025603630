export const layout = {
  namespaced: true,
  state: {
    appBarExtended: false,
  },
  actions: {},
  mutations: {
    openAppBarExtension(state) {
      state.appBarExtended = true;
    },
    closeAppBarExtension(state) {
      state.appBarExtended = false;
    },
  },
  getters: {
    appBarExtended({ appBarExtended }) {
      return appBarExtended;
    },
  },
};
