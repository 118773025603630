import Vue from "vue";
import VueI18n from "vue-i18n";
import moment from "moment";

Vue.use(VueI18n);

const locale = process.env.VUE_APP_I18N_LOCALE || "de";
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de";

moment.locale(locale);

export default new VueI18n({
  locale,
  fallbackLocale,
  messages: {
    de: require("./locales/de.json"),
    en: require("./locales/en.json"),
  },
});
