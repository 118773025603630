export default {
  path: "leader",
  component: () =>
    import(/* webpackChunkName: "leader" */ "@/layouts/Leader.vue"),
  meta: { middleware: ["role:leader"] },
  children: [
    {
      path: "user/create",
      name: "user.create",
      component: () =>
        import(/* webpackChunkName: "user.create" */ "@/views/user/Create.vue"),
    },
    {
      path: "user/edit/:id",
      name: "user.edit",
      component: () =>
        import(/* webpackChunkName: "user.edit" */ "@/views/user/Edit.vue"),
    },
    {
      path: "team",
      name: "team",
      component: () =>
        import(/* webpackChunkName: "team" */ "@/views/team/Overview.vue"),
    },
    {
      path: "team/logo",
      name: "team.logo",
      component: () =>
        import(/* webpackChunkName: "team.logo" */ "@/views/team/Logo.vue"),
    },
    {
      path: "team/:id/invite",
      name: "team.invite",
      component: () =>
        import(/* webpackChunkName: "team.invite" */ "@/views/team/Invite.vue"),
    },
    {
      path: "team/activity",
      name: "team.activity",
      component: () =>
        import(
          /* webpackChunkName: "team.activity" */ "@/views/team/Activity.vue"
        ),
    },
    {
      path: "/work-session/export",
      name: "work-session.export",
      component: () =>
        import(
          /* webpackChunkName: "work-session.export" */ "@/views/work-session/Export.vue"
        ),
    },
    {
      path: "/work-session/import",
      name: "work-session.import",
      component: () =>
        import(
          /* webpackChunkName: "work-session.import" */ "@/views/work-session/Import.vue"
        ),
    },
    {
      path: "/work-session/lock",
      name: "work-session.lock",
      component: () =>
        import(
          /* webpackChunkName: "work-session.lock" */ "@/views/work-session/Lock.vue"
        ),
    },
    {
      path: "absent/create",
      name: "leader.absent.create",
      component: () =>
        import(
          /* webpackChunkName: "absent.create" */ "@/views/absent/Create.vue"
        ),
      props: { forOthers: true },
    },
    {
      path: "/urlaub/verwalten/:highlightId?",
      name: "vacation.managment",
      props({ params }) {
        params.highlightId = +params.highlightId;
        return params;
      },
      component: () =>
        import(
          /* webpackChunkName: "vacation.managment" */ "@/views/vacation/Managment.vue"
        ),
    },
    {
      path: "/urlaub/reminder/create",
      name: "vacation.reminder.create",
      component: () =>
        import(
          /* webpackChunkName: "vacation.managment" */ "@/views/vacation/reminder/create.vue"
        ),
    },
    {
      path: "/report",
      name: "report.list",
      component: () =>
        import(/* webpackChunkName: "report.list" */ "@/views/report/List.vue"),
    },
    {
      path: "/report/create",
      name: "report.create",
      component: () =>
        import(
          /* webpackChunkName: "report.create" */ "@/views/report/Create.vue"
        ),
    },
    {
      path: "/sick-leave",
      name: "sick-leave.list",
      component: () =>
        import(
          /* webpackChunkName: "sick-leave.list" */ "@/views/sick-leave/List.vue"
        ),
    },
    {
      path: "/sick-leave/create",
      name: "sick-leave.create",
      component: () =>
        import(
          /* webpackChunkName: "sick-leave.create" */ "@/views/sick-leave/Create.vue"
        ),
    },
    {
      path: "/user/:user_id/contract/create",
      name: "user.contract.create",
      component: () =>
        import(
          /* webpackChunkName: "user.contract.create" */ "@/views/contract/Create.vue"
        ),
    },
    {
      path: "/contract/list/:user_id?",
      name: "contract.list",
      props: (route) => ({ user_id: parseInt(route.params.user_id) || null }),
      component: () =>
        import(
          /* webpackChunkName: "contract.list" */ "@/views/contract/List.vue"
        ),
    },
    {
      path: "/contract/:id/edit",
      name: "contract.edit",
      component: () =>
        import(
          /* webpackChunkName: "contract.edit" */ "@/views/contract/Edit.vue"
        ),
    },
    {
      path: "/invoice/template",
      name: "invoice.template",
      component: () =>
        import(
          /* webpackChunkName: "invoice.template" */ "@/views/invoice/Template.vue"
        ),
    },
  ],
};
