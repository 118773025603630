import sort from "@/functions/sort";
import { http } from "@/ajax";

export const customerSorted = {
  namespaced: true,
  state: {
    cache: [],
  },
  actions: {
    load({ commit }) {
      return http.get(`/api/v1/customer-cache`).then(({ data }) => {
        commit("setCache", data);
      });
    },
    clear({ commit }) {
      commit("setCache", []);
    },
  },
  mutations: {
    setCache(state, data) {
      state.cache = data;
    },
  },
  getters: {
    sorted: (state, getters, rootState, rootGetters) => (resourceId) =>
      sort.withCache(rootGetters["resources/data"](resourceId), state.cache),
  },
};
