import Vue from "vue";
import store from "@/store";
import router from "./router";
import axios from "axios";

export const http = axios;

Vue.prototype.$http = axios;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([400, 401, 403, 500, 502].includes(error.response.status)) {
      Vue.notify({
        type: "error",
        title: error.response.data.message,
        text: renderErrors(error.response.data.errors),
      });
    }

    if ([429].includes(error.response.status)) {
      Vue.notify({
        type: "error",
        title: error.response.data.message,
        text: `Es wurden mehr als ${error.response.headers["x-ratelimit-limit"]} Aufrufe in der letzten Minute ausgelöst. Bitte warten Sie eine Minute bevor Sie neue Aufrufe ausführen.`,
      });
    }

    if (error.response.status === 401) {
      // server rejected request (auth-check is handled in auth.js)
      if (error.response.config.url !== "/api/v1/validate") {
        store.commit("auth/logout");
        router.push({ name: "login" });
      }
    }

    return Promise.reject(error);
  }
);

export function setAuthorizationToken(token) {
  axios.defaults.headers.common["Authorization"] = token;
}

function renderErrors(errors) {
  if (!errors) {
    return undefined;
  }

  let render = "<ul>";

  Object.keys(errors).forEach((error) => {
    render = `${render} <li>${errors[error]}</li>`;
  });

  return `${render}</ul>`;
}
