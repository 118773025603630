import Vue from "vue";

Vue.use({
  install() {
    Vue.prototype.$number = function (number, options = {}) {
      const defaults = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      const formatter = new Intl.NumberFormat("de-DE", {
        ...defaults,
        ...options,
      });

      return formatter.format(number);
    };
  },
});
