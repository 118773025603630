import Vue from "vue";

Vue.use({
  install(Vue) {
    /**
     * cleanup any resource requested by a component on destroy
     */
    Vue.mixin({
      destroyed() {
        if (this.$resourceStoreIds) {
          const resources = Object.keys(this.$resourceStoreIds);
          resources.forEach((resource) => {
            this.$resourceStoreIds[resource].forEach((id) => {
              this.$store.commit(`resources/delete`, id);
            });
          });
        }
      },
    });

    /**
     * request any model, collection from backend
     *
     * @param  {String} resource the resource that is requested
     * @param  {Object} query    query params for the resource collection
     * @return {Promise}
     */
    Vue.prototype.$resource = function (resource, query = {}) {
      if (this.$resourceStoreIds === undefined) {
        this.$resourceStoreIds = {};
      }

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(`resources/read`, {
            query,
            resource,
          })
          .then((id) => {
            if (!Array.isArray(this.$resourceStoreIds[resource])) {
              this.$resourceStoreIds[resource] = [];
            }
            this.$resourceStoreIds[resource].push(id);
            resolve(id);
          })
          .catch((response) => {
            reject(response);
          });
      });
    };

    /**
     * Get resource from store
     *
     * @param  {String} id     resource id in the store
     * @param  {String} getter getter used to get the resource from store
     * @return {Array}
     */
    Vue.prototype.$resources = function (id, getter = "read") {
      return this.$store.getters["resources/" + getter](id);
    };
  },
});
