<template>
  <v-snackbar
    v-model="snackWithButtons"
    :timeout="timeout"
    bottom
    left
    class="snack"
  >
    <v-row no-gutters>
      <v-col class="flex-shrink-0">
        <span class="text-body-1">{{ snackWithBtnText }}</span>
      </v-col>
      <v-col class="flex-grow-0">
        <v-btn color="primary" small @click.native="refreshApp">
          {{ snackBtnText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "AppUpdateComponent",
  data() {
    return {
      refreshing: false,
      registration: null,
      snackBtnText: "OK",
      snackWithBtnText: "Es ist ein Update verfügbar.",
      snackWithButtons: false,
      timeout: -1,
    };
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    /* navigator.serviceWorker.addEventListener("controllerchange", () => { */
    /*   if (this.refreshing) return; */
    /*   window.location.reload(); */
    /*   this.refreshing = true; */
    /* }); */
  },
  methods: {
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;

      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) =>
          Promise.all(
            registrations.map((worker) => worker.unregister())
          ).finally(() => window.location.reload())
        );
    },
  },
};
</script>
