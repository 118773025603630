import { http } from "@/ajax";

export const teamOverview = {
  namespaced: true,
  state: {
    teamstate: [],
  },
  actions: {
    bootstrap({ commit }) {
      http
        .get("/api/v1/user", { params: { append: "currentActivity" } })
        .then(({ data }) => {
          commit("setState", data);
        });
    },
  },
  mutations: {
    setState(state, data) {
      state.teamstate = data;
    },
    updateUser(state, user) {
      let index = state.teamstate.findIndex((u) => u.id === user.id);

      if (index >= 0) {
        state.teamstate.splice(index, 1, user);
      }
    },
  },
  getters: {
    state({ teamstate }) {
      return teamstate;
    },
  },
};
