export default {
  path: "",
  component: () =>
    import(/* webpackChunkName: "Proxy" */ "@/layouts/Proxy.vue"),
  children: [
    {
      path: "/email/list",
      name: "email.list",
      component: () =>
        import(/* webpackChunkName: "email.list" */ "@/views/email/List.vue"),
    },
    {
      path: "/email/create",
      name: "email.create",
      component: () =>
        import(
          /* webpackChunkName: "email.create" */ "@/views/email/Create.vue"
        ),
    },
    {
      path: "/email/edit/:id",
      name: "email.edit",
      component: () =>
        import(/* webpackChunkName: "email.edit" */ "@/views/email/Edit.vue"),
    },
    {
      path: "/email/:id/alias/create",
      name: "email.alias.create",
      component: () =>
        import(
          /* webpackChunkName: "email.alias.create" */ "@/views/alias/Create.vue"
        ),
    },
    {
      path: "/alias/edit/:alias_id",
      name: "alias.edit",
      component: () =>
        import(
          /* webpackChunkName: "email.alias.edit" */ "@/views/alias/Edit.vue"
        ),
    },
    {
      path: "/email/information/:emailAddress",
      name: "email.information",
      component: () =>
        import(
          /* webpackChunkName: "email.information" */ "@/views/email/Information.vue"
        ),
    },
  ],
};
