import Vue from "vue";
import { http } from "@/ajax";

export const avatar = {
  namespaced: true,
  state: {},
  actions: {
    load({ state, dispatch }, { id, size }) {
      if (state[id]?.[size] === undefined) {
        return dispatch("forceLoad", { id, size });
      }
    },
    forceLoad({ commit, getters }, { id, size }) {
      return http
        .get(`/api/v1/user/${id}/avatar`, {
          responseType: "blob",
          params: { size },
        })
        .then(({ status, data }) => {
          commit("setAvatar", { data: status === 200 ? data : null, id, size });
        })
        .then(() => getters["avatar"](id, size));
    },
    clear({ dispatch, state }, id) {
      return Promise.all(
        Object.keys(state[id]).map((size) =>
          dispatch("forceLoad", { id, size: size })
        )
      );
    },
  },
  mutations: {
    setAvatar(state, { data, id, size }) {
      if (!state[id]) {
        Vue.set(state, id, {});
      }
      if (data) {
        data = URL.createObjectURL(data);
      }
      Vue.set(state[id], size, data);
      return data;
    },
  },
  getters: {
    avatar: (state) => (id, size) => {
      return state[id]?.[size];
    },
    hasAvatar: (state) => (id, size) => {
      return state[id]?.[size] ?? false;
    },
  },
};
