export const invoice = {
  namespaced: true,
  state: {
    defaultItem: {
      amount: 1,
      price: null,
      tax: 19,
      description: null,
    },
    items: [],
  },
  mutations: {
    store(state, items) {
      state.items = items;
    },
    clear(state) {
      state.items = [];
    },
  },
  getters: {
    items(state) {
      return state.items;
    },
    defaultItem(state) {
      return state.defaultItem;
    },
  },
};
