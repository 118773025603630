export const stripe = {
  namespaced: true,
  state: {
    loaded: false,
  },
  actions: {
    load({ commit, state }) {
      if (state.loaded === false) {
        return new Promise((resolve, reject) => {
          let script = document.createElement("script");
          script.onload = () => {
            commit("load");
            resolve();
          };
          script.setAttribute("src", "https://js.stripe.com/v3/");
          document.head.appendChild(script);
        });
      }
    },
  },
  mutations: {
    load(state) {
      state.loaded = true;
    },
  },
};
