export default {
  path: "",
  component: () =>
    import(/* webpackChunkName: "proxy" */ "@/layouts/Proxy.vue"),
  children: [
    {
      path: "/urlaub/list",
      name: "vacation.list",
      component: () =>
        import(
          /* webpackChunkName: "vacation.list" */ "@/views/vacation/List.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/urlaub/beantragen",
      name: "vacation.create",
      component: () =>
        import(
          /* webpackChunkName: "vacation.create" */ "@/views/vacation/Create.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/customer/list",
      name: "customer.list",
      component: () =>
        import(
          /* webpackChunkName: "customer.list" */ "@/views/customer/List.vue"
        ),
      meta: { middleware: ["can:manage_time_tracking"] },
    },
    {
      path: "/customer/create",
      name: "customer.create",
      component: () =>
        import(
          /* webpackChunkName: "customer.create" */ "@/views/customer/Create.vue"
        ),
      meta: { middleware: ["can:manage_time_tracking"] },
    },
    {
      path: "/customer/:id/edit",
      name: "customer.edit",
      component: () =>
        import(
          /* webpackChunkName: "customer.edit" */ "@/views/customer/Edit.vue"
        ),
      meta: { middleware: ["can:manage_time_tracking"] },
    },
    {
      path: "/customer/:id/edit-address",
      name: "customer.address.edit",
      component: () =>
        import(
          /* webpackChunkName: "customer.address.edit" */ "@/views/customer/Address.vue"
        ),
      meta: { middleware: ["can:manage_time_tracking"] },
    },
    {
      path: "/work-session",
      name: "work-session",
      component: () =>
        import(
          /* webpackChunkName: "work-session" */ "@/views/WorkSession.vue"
        ),
      meta: {
        mobile: true,
      },
      props: {
        tab: 0,
      },
    },
    {
      path: "/work-session/month",
      name: "work-session.month",
      component: () =>
        import(
          /* webpackChunkName: "work-session" */ "@/views/WorkSession.vue"
        ),
      meta: {
        mobile: true,
      },
      props: {
        tab: 1,
      },
    },
    {
      path: "/work-session/table",
      name: "work-session.table",
      component: () =>
        import(
          /* webpackChunkName: "work-session" */ "@/views/WorkSession.vue"
        ),
      meta: {
        mobile: true,
      },
      props: {
        tab: 2,
      },
    },
    {
      path: "/work-session/overtime",
      name: "work-session.overtime",
      component: () =>
        import(
          /* webpackChunkName: "work-session" */ "@/views/WorkSession.vue"
        ),
      meta: {
        mobile: true,
      },
      props: {
        tab: 3,
      },
    },
    {
      path: "/work-session/create",
      name: "work-session.create",
      component: () =>
        import(
          /* webpackChunkName: "work-session.create" */ "@/views/work-session/Create.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/work-session/edit-current",
      name: "work-session.edit-current",
      component: () =>
        import(
          /* webpackChunkName: "work-session.edit-current" */ "@/views/work-session/EditCurrent.vue"
        ),
      meta: {
        mobile: true,
      },
    },
    {
      path: "/work-session/edit/:id",
      name: "work-session.detail",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "work-session.detail" */ "@/views/work-session/Detail.vue"
        ),
    },
    {
      path: "/work-session/:id/edit",
      name: "work-session.edit",
      component: () =>
        import(
          /* webpackChunkName: "work-session.edit" */ "@/views/work-session/Edit.vue"
        ),
    },
    {
      path: "/absent/create",
      name: "absent.create",
      component: () =>
        import(
          /* webpackChunkName: "absent.create" */ "@/views/absent/Create.vue"
        ),
    },
  ],
};
