import Vue from "vue";
import Vuex from "vuex";

import { auth } from "@/store/auth";
import { resources } from "@/store/resources";
import { stripe } from "@/store/stripe";
import { currentSession } from "@/store/currentSession";
import { avatar } from "@/store/avatar";
import { layout } from "@/store/layout";
import { invoice } from "@/store/invoice";
import { meta } from "@/store/meta";
import { customerSorted } from "@/store/customerSorted";
import { teamOverview } from "@/store/teamOverview";
import { time } from "@/store/time";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    layout,
    auth,
    resources,
    stripe,
    currentSession,
    avatar,
    invoice,
    meta,
    customerSorted,
    teamOverview,
    time,
  },
  state: {},
  mutations: {},
  actions: {},
});

// make time module tick like a clock
setInterval(() => {
  store.commit("time/update");
}, 1000);

export default store;
