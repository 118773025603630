import Vue from "vue";
import store from "@/store";

Vue.use({
  install() {
    Vue.prototype.$can = function (...args) {
      return store.getters["auth/can"](...args);
    };
  },
});
