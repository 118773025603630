import store from "@/store";
import moment from "moment";
import i18n from "@/i18n";

export class Model {
  constructor(data = {}) {
    this.$dates = [];

    this.$setup();
    data = this.$datesFromUTC(data);

    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }

  $resource() {
    throw "A Model needs to define the $resource method to identify which resource it belongs to";
  }

  $attributes() {
    throw "A Model needs to define the $attributes method to define which attributes should be sent to backend on save or update";
  }

  $values() {
    return this.$attributes().reduce((accumulator, field) => {
      accumulator[field] = this[field];
      return accumulator;
    }, {});
  }

  $datesToUTC(data) {
    this.$dates.forEach((date) => {
      let splits = date.split("|");
      let dateKey = splits.shift();
      if (moment(data[dateKey]).isValid() && data[dateKey]) {
        let newDate = moment(data[dateKey]);
        if (!splits.includes("date")) {
          newDate.utc();
        }
        data[dateKey] = newDate.format("YYYY-MM-DD HH:mm:ss");
      }
    });

    return data;
  }

  $datesFromUTC(data) {
    this.$dates.forEach((date) => {
      let splits = date.split("|");
      let dateKey = splits.shift();
      if (moment(data[dateKey]).isValid() && data[dateKey]) {
        let parsedDate = null;
        if (splits.includes("date")) {
          parsedDate = moment(data[dateKey]);
        } else {
          parsedDate = moment.utc(data[dateKey]).local();
        }
        let format =
          splits.find((prop) => prop.includes("format:"))?.split(":")[1] ??
          "YYYY-MM-DD HH:mm:ss";
        data[dateKey] = parsedDate.format(format);
      }
    });

    return data;
  }

  $save() {
    const action = this.id ? "resources/update" : "resources/create";

    return store.dispatch(action, {
      id: this.id,
      resource: this.$resource(),
      data: {
        ...this.$values(),
      },
    });
  }

  $t(value) {
    return i18n.t(value);
  }

  $delete() {
    return store.dispatch("resources/delete", {
      resource: this.$resource(),
      id: this.id,
    });
  }

  $clone() {
    return Object.assign(new this.constructor(), this);
  }
}
