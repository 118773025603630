export const time = {
  namespaced: true,
  state: {
    // this is a timestamp in microseconds
    timestamp: Date.now(),
  },
  mutations: {
    update(state) {
      state.timestamp = Date.now();
    },
  },
  getters: {
    timestamp: (state) => state.timestamp,
  },
};
