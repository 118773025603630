import Echo from "laravel-echo";
import store from "@/store";
import router from "@/router";
import { bus } from "@/bus";

window.io = require("socket.io-client");

let host = window.location.hostname;
if (window.location.port) {
  host += ":" + window.location.port;
}

export const echo = new Echo({
  broadcaster: "socket.io",
  host,
});

export function joinPrivateChannels() {
  setSocketAuthorizationToken(`Bearer ${store.state.auth.encodedToken}`);

  echo
    .private(`user.${store.getters["auth/id"]}`)
    .listen("UserUpdated", ({ user }) => {
      store.commit("auth/user", user);
    })
    .listen("TokenRevoked", ({ id }) => {
      if (store.getters["auth/tokenIdMatches"](id)) {
        store.commit("auth/logout");
        router.push({ name: "login" });
      }
    })
    .listen("WorkSessionUpdated", ({ session }) => {
      bus.$emit("WorkSessionUpdated", session);
      store.dispatch("currentSession/load");
    })
    .listen("CustomerCacheUpdated", ({ cache }) => {
      store.commit("customerSorted/setCache", cache);
    });

  echo
    .private(`team.${store.getters["auth/team"].id}.leaders`)
    .listen("TeamMemberStatusChange", ({ user }) => {
      store.commit("teamOverview/updateUser", user);
    });
}

export function leavePrivateChannels(id) {
  if (id) {
    echo.leave(`user.${id}`);
  }
  setSocketAuthorizationToken(undefined);
}

export function setSocketAuthorizationToken(token) {
  echo.connector.options.auth.headers["Authorization"] = token;
}
