import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import publicRoutes from "@/routes/public";
import memberRoutes from "@/routes/member";
import leaderRoutes from "@/routes/leader";
import adminRoutes from "@/routes/admin";
import vuerify from "@/plugins/vuetify";
import middleware from "./middleware";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    publicRoutes,
    {
      path: "",
      component: () =>
        import(/* webpackChunkName: "proxy" */ "@/layouts/Proxy.vue"),
      beforeEnter(to, from, next) {
        return store.getters["auth/guest"]
          ? next({ name: "login" })
          : store.dispatch("currentSession/load").then(() => next());
      },
      children: [memberRoutes, leaderRoutes, adminRoutes],
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Get middleware names from route and parents
  let middlewares = to.matched
    .filter((i) => i.meta.middleware !== undefined)
    .map((i) => i.meta.middleware)
    .flat()
    .map((i) => {
      // split at first : and pass the rest as argument
      let names = i.split(/:(.+)/);

      // Execute middleware
      if (middleware.hasOwnProperty(names[0])) {
        return (
          middleware[names[0]](
            names.length > 1 ? names[1] : undefined,
            next
          ) !== undefined
        );
      } else {
        // eslint-disable-next-line no-console
        console.error(`Middleware "${names}" was not found, define it`);
        throw 0;
      }
    });
  if (!middlewares.includes(false)) {
    if (
      window.innerWidth < vuerify.preset.breakpoint.thresholds.xs &&
      !to.meta.mobile
    ) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

export default router;
