export default {
  /**
   * Sort items based on position in cache
   * @param {Array} items objects with an id
   * @param {Array} cache ids to be shown first
   */
  withCache: function (items, cache) {
    items.sort(function (a, b) {
      let indexA = cache.findIndex((el) => el === a.id);
      let indexB = cache.findIndex((el) => el === b.id);

      if (indexA === indexB) {
        return 0;
      }
      if (indexA !== -1 && (indexB === -1 || indexA < indexB)) {
        return -1;
      }
      return 1;
    });
    return items;
  },
};
