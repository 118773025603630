import { Model } from "@/models/Model";

export class VacationReminder extends Model {
  $resource() {
    return "vacationReminder";
  }

  $attributes() {
    return ["lead_time_in_days", "min_vacation_days"];
  }

  $setup() {}

  get computed_weeks() {
    return (this.lead_time_in_days - (this.lead_time_in_days % 7)) / 7;
  }

  get computed_days() {
    return this.lead_time_in_days % 7;
  }
}
