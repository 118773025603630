import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/can";
import "./plugins/download";
import "./plugins/resources";
import "./plugins/number";
import Notifications from "vue-notification";
import "./ajax";
import App from "./App";
import Maintance from "./Maintance";
import router from "./router";
import store from "./store";
import "./socket";
import "./registerServiceWorker";
import "@fortawesome/fontawesome-pro/css/all.css";
import i18n from "./i18n";
import "./assets/css/main.css";

Vue.config.productionTip = false;
Vue.use(Notifications);

Vue.filter("humanDuration", (duration) => {
  const hours = parseInt(duration.asHours());
  const minutes = (parseInt(duration.asMinutes()) % 60).toLocaleString(
    "de-DE",
    {
      minimumIntegerDigits: 2,
    }
  );
  const seconds = (parseInt(duration.asSeconds()) % 60).toLocaleString(
    "de-DE",
    {
      minimumIntegerDigits: 2,
    }
  );
  return `${hours}:${minutes}:${seconds}`;
});

import SpinnerComponent from "@/components/Spinner.vue";
Vue.component("spinner-component", SpinnerComponent);

store
  .dispatch("auth/bootstrap")
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");

    if (
      ["/", "/login"].includes(window.location.pathname) &&
      store.getters["auth/check"]
    ) {
      router.push({ name: "home" });
    }

    store.commit("meta/bootstrap");
  })
  .catch(() => {
    new Vue({
      vuetify,
      i18n,
      render: (h) => h(Maintance),
    }).$mount("#app");
  });
