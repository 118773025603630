import { http } from "@/ajax";
import { uniqueId } from "lodash";
import { Model } from "@/models/Model";
import { WorkSession } from "@/models/WorkSession";
import { Vacation } from "@/models/Vacation";
import { VacationReminder } from "@/models/VacationReminder";
import { Invoice } from "@/models/Invoice";
import { SickLeave } from "@/models/SickLeave";

const resourceMapping = {
  workSession: WorkSession,
  vacation: Vacation,
  vacationReminder: VacationReminder,
  invoice: Invoice,
  sickLeave: SickLeave,
};

export const resources = {
  namespaced: true,
  state: { collections: {} },
  actions: {
    read({ commit }, { query = {}, resource }) {
      return new Promise((resolve, reject) => {
        http
          .get(`/api/v1/${resource}`, {
            params: query,
          })
          .then(({ data }) => {
            const id = uniqueId(`r-${resource}-`);
            commit("read", { id, data, resource });
            resolve(id);
          })
          .catch((response) => reject(response));
      });
    },
    create(store, { resource, data }) {
      if (typeof data.$save === "function") {
        return data.$save();
      }

      if (resourceMapping[resource]) {
        data = new resourceMapping[resource]().$datesToUTC(data);
      }

      return http.post(`/api/v1/${resource}/create`, data);
    },
    update(store, { resource, id, data }) {
      if (typeof data.$save === "function") {
        return data.$save();
      }

      if (resourceMapping[resource]) {
        data = new resourceMapping[resource]().$datesToUTC(data);
      }

      return http.post(`/api/v1/${resource}/${id}`, data);
    },
    delete(store, { resource, id }) {
      return http.delete(`/api/v1/${resource}/${id}`);
    },
  },
  mutations: {
    read(state, { id, data, resource }) {
      if (resourceMapping[resource]) {
        // is there a model mapping
        if (data.data !== undefined) {
          // is there pagination
          data.data = data.data.map(
            (modelData) => new resourceMapping[resource](modelData)
          );
        } else {
          data = data.map(
            (modelData) => new resourceMapping[resource](modelData)
          );
        }
      }

      state.collections[id] = data;
    },
    update(state, { id, data }) {
      let elem = (
        Array.isArray(state.collections[id])
          ? state.collections[id]
          : state.collections[id].data
      ).find((element) => {
        return element.id === data.id;
      });
      if (elem) {
        let newModel =
          elem instanceof Model ? new elem.constructor(data) : data;
        Object.assign(elem, newModel);
      }
    },
    delete(state, id) {
      state.collections[id] = null;
    },
  },
  getters: {
    read: (state) => (id) => {
      return state.collections[id] ? state.collections[id] : [];
    },
    element: (state) => (id, key) => {
      return state.collections[id] && state.collections[id][key]
        ? state.collections[id][key]
        : null;
    },
    data: (state) => (id) => {
      return state.collections[id] ? state.collections[id].data : [];
    },
    meta: (state) => (id) => {
      return state.collections[id] ? state.collections[id].meta : [];
    },
  },
};
