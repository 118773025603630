import store from "@/store";
import { WorkSession } from "@/models/WorkSession";

let timeout;
export const currentSession = {
  namespaced: true,
  state: {
    session: new WorkSession(),
    defaultStartOption: {},
  },
  actions: {
    load({ commit }) {
      store
        .dispatch("resources/read", {
          query: {
            "filter[finished]": false,
            "filter[user_id]": store.getters["auth/id"],
            include: "customer",
          },
          resource: "workSession",
        })
        .then((id) => store.getters["resources/read"](id)[0])
        .then((resource) => commit("setSession", resource));
    },
    start({ state }) {
      return state.session.$resume();
    },
    startBreak({ state, commit }) {
      commit("setType", 1);
      commit("setDescription", "Pause");
      return state.session.$resume();
    },
    stop({ commit, state }) {
      commit("setEnd", "now");
      return state.session.$save();
    },
    updateDescription({ commit, dispatch }, description) {
      if (timeout) {
        window.clearTimeout(timeout);
      }
      return new Promise((resolve) => {
        timeout = window.setTimeout(() => {
          commit("setDescription", description);
          dispatch("saveIfRunning").then((data) => resolve(data));
        }, 800);
      });
    },
    updateCustomer({ commit, dispatch }, customer) {
      commit("setCustomer", customer);
      return dispatch("saveIfRunning");
    },
    saveIfRunning({ getters, state }) {
      if (getters.isRunning) {
        return state.session.$save();
      }
    },
  },
  mutations: {
    setSession(state, newSession) {
      state.session =
        newSession ??
        new WorkSession({
          customer_id: store.getters["auth/primaryCustomer"].id,
          description: "Arbeit",
          started_at: "now",
        });
      store.commit(
        newSession
          ? "layout/openAppBarExtension"
          : "layout/closeAppBarExtension"
      );
    },
    setCustomer(state, customer) {
      state.session.customer_id = customer;
    },
    setDescription(state, description) {
      state.session.description = description;
    },
    setType(state, type) {
      state.session.type = type;
    },
    setEnd(state, end) {
      state.session.ended_at = end;
    },
  },
  getters: {
    isRunning({ session }) {
      return session.id !== null && session.id !== undefined;
    },
    isWorking({ session }, { isRunning }) {
      return isRunning ? session.type === 0 : false;
    },
    isOnBreak({ session }, { isRunning }) {
      return isRunning ? session.type === 1 : false;
    },
    session({ session }) {
      return session;
    },
  },
};
