import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/es5/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: false,
      light: {
        primary: "#185ea3",
        secondary: "#ffffff",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  customProperties: true,
  icons: {
    iconfont: "fa",
  },
  lang: {
    locales: { de },
    current: "de",
  },
});
